export default function getLevelFromPercent(
  percent: number,
  maxPercent: number = 100,
  levels: number = 5
): number {
  // `percent` could be larger than `maxPercent` but anything larger or equal
  // to `maxPercent` will be max level.
  const effectivePercent = Math.min(percent, maxPercent);
  const spread = maxPercent / levels;
  // With a percent of 0, the level should be minimum value of 1.
  const calculatedLevel = Math.ceil(effectivePercent / spread);
  return Math.max(calculatedLevel, 1);
}
