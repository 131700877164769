import getCalculatedLevelFromPercent from "~/utils/getCalculatedLevelFromPercent";

export default function LineDial({
  percent,
  minPercent = 0,
  barColor = "black",
  currentLevelLabelColor = "black",
  currentLevelLabelStroke = "black",
  showValue = true,
  ...rest
}: {
  percent: number;
  minPercent?: number;
  barColor: string;
  currentLevelLabelColor?: string;
  currentLevelLabelStroke?: string;
  showValue?: boolean;
}) {
  const visiblePercent = showValue ? Math.max(percent, minPercent) : 0;
  console.log("LineDial", showValue, percent, minPercent, visiblePercent);

  // The circumference of the circle is 314.16
  // Since this is a half circle, the circumference is 157.08, which allows us
  // to calculate the stroke-dasharray value
  const stokeDashArrayValue = (157 * visiblePercent) / 100;

  const currentLevel = getCalculatedLevelFromPercent(visiblePercent, 5);

  return (
    <svg
      className="line-dial"
      viewBox="-10 0 140 75"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        className="line-dial-bar-bg"
        d="M 10,70 A 50,50 0 0 1 110,70"
        fill="none"
        stroke="#666"
        strokeWidth="10"
      />
      <path
        className="line-dial-bar-bg"
        d="M 10,70 A 50,50 0 0 1 110,70"
        fill="none"
        stroke="rgb(243 244 246)"
        strokeWidth="8"
      />
      <path
        className="line-dial-bar"
        d="M 10,70 A 50,50 0 0 1 110,70"
        fill="none"
        style={{ opacity: visiblePercent === 0 ? 0 : 1 }}
        stroke={barColor}
        strokeWidth="6"
        strokeDasharray={`${stokeDashArrayValue} 10000`}
      />

      <line x1="60" y1="70" x2="-140" y2="70" />
      <line x1="60" y1="70" x2="-101.80" y2="-47.56" />
      <line x1="60" y1="70" x2="-1.80" y2="-120.21" />
      <line x1="60" y1="70" x2="121.80" y2="-120.21" />
      <line x1="60" y1="70" x2="221.80" y2="-47.56" />
      <line x1="60" y1="70" x2="260" y2="70" />

      <text
        x="-2"
        y="51.46"
        className={currentLevel === 1 ? "high-level" : ""}
        transform="rotate(-72,2.94,51.46)"
        fontFamily="Times New Roman"
        fill={currentLevel === 1 ? currentLevelLabelColor : "black"}
        stroke={currentLevel === 1 ? currentLevelLabelStroke : ""}
        fontWeight="bold"
        strokeWidth={0.4}
      >
        L1
      </text>
      <text
        x="20"
        y="21.46"
        className={currentLevel === 2 ? "high-level" : ""}
        fill={currentLevel === 2 ? currentLevelLabelColor : "black"}
        transform="rotate(-36,24.73,21.46)"
        fontFamily="Times New Roman"
        stroke={currentLevel === 2 ? currentLevelLabelStroke : ""}
        fontWeight="bold"
        strokeWidth={0.4}
      >
        L2
      </text>
      <text
        x="53"
        y="10"
        className={currentLevel === 3 ? "high-level" : ""}
        fill={currentLevel === 3 ? currentLevelLabelColor : "black"}
        transform="rotate(0,60,10)"
        fontFamily="Times New Roman"
        stroke={currentLevel === 3 ? currentLevelLabelStroke : ""}
        fontWeight="bold"
        strokeWidth={0.4}
      >
        L3
      </text>
      <text
        x="88.27"
        y="21.46"
        className={currentLevel === 4 ? "high-level" : ""}
        fill={currentLevel === 4 ? currentLevelLabelColor : "black"}
        transform="rotate(36,95.27,21.46)"
        fontFamily="Times New Roman"
        stroke={currentLevel === 4 ? currentLevelLabelStroke : ""}
        fontWeight="bold"
        strokeWidth={0.4}
      >
        L4
      </text>
      <text
        x="110.06"
        y="51.46"
        className={currentLevel === 5 ? "high-level" : ""}
        fill={currentLevel === 5 ? currentLevelLabelColor : "black"}
        transform="rotate(72,117.06,51.46)"
        fontFamily="Times New Roman"
        stroke={currentLevel === 5 ? currentLevelLabelStroke : ""}
        fontWeight="bold"
        strokeWidth={0.4}
      >
        L5
      </text>
    </svg>
  );
}
